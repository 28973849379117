import React from "react"
import { Link } from 'gatsby';
import styled from 'styled-components'
import { breakpoints } from '../styles/Variables'
import ContactButton from "../components/ContactButton";

const IndexPageStyles = styled.div`
  position: relative;
  height: 100%;

  .intro {
    /* height: 100vh; */

    .content {
      position: absolute;
      /* left: var(--col2); */
      left: 8vh;
      top: calc(60% + 1rem);

      p {
        margin: 0;
      }

      a {
        margin-right: 3em;
        letter-spacing: 0.5px;
      }

      .technologies {
        max-width: 14em;
      }

      nav {
        margin-top: 2rem;
      }

      .tiny {
        margin-left: -0.5em;
      }


      // Desktop
      /* @media (min-width: ${breakpoints.breakLg}) {
        left: 55%;
        padding-right: 2rem;
      } */

      // Extreme landscape (wide and short) – adjust text flow
      @media (max-height: 750px) {
        /* left: var(--col2); */

        .textWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: var(--col10);
        }
      }

      // Small screens
      @media (max-width: ${breakpoints.breakSm}) {
        top: calc(58% + 1rem);

        .textWrapper {
          display: block;
        }

        h1 {
          font-size: var(--fsTitle2);
        }

        .technologies {
          font-size: var(--fsBody);
        }

        nav {
          width: calc(100% - 2rem);
          position: fixed;
          bottom: 1rem;
          left: 1rem;
          display: flex;
          justify-content: space-between;
        }
      }

    }
  }


`
const IndexPage = () => {
  return <IndexPageStyles>
    <section className="intro">
      <div className="content">
        <div className="textWrapper">

          <div className="textBlock">
            <span className="tiny">we do</span>
            <h1>
              Full stack web development
            </h1>
          </div>

          <div className="textBlock">
            <span className="tiny">with</span>
            <h2 className="technologies">
              React, Gatsby, Sanity, Express,
              Next, WordPress, Keystone
            </h2>
          </div>
        </div>
        <nav>
          <Link to="/work">Work</Link>
          <ContactButton />
        </nav>
      </div>
    </section>
    
    <section className="work">
      {/* TODO: grab posts here */}
    </section>
  </IndexPageStyles>
}

export default IndexPage
